import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Footer() {
  const [goUp, setGoUp] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      setGoUp(window.pageYOffset > 100);
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAdmin(false);
        return;
      }

      try {
        // Verify the token with the backend
        const response = await axios.get(
          "https://avs-battery.onrender.com/api/admin/verifyToken",
          // "http://localhost:5000/api/admin/verifyToken",
          {
            headers: { "x-auth-token": token },
          }
        );

        if (response.data.success) {
          setIsAdmin(true); // Token is valid
        } else {
          setIsAdmin(false); // Token is invalid
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsAdmin(false); // Error during verification
      }
    };

    verifyToken();
  }, []);

  // Data arrays for the footer
  const companyLinks = [
    { label: "About", to: "/About" },
    { label: "Batteries", to: "/Batteries" },
    { label: "Gallery", to: "/Gallery" },
    { label: "Testimonials", to: "/testimonials" },
    { label: "Contact", to: "/contact" },
  ];

  const warrantyLinks = [
    { label: "Registration Page", to: "/RegistrationPage" },
    { label: "View Registration", to: "/ViewRegistration" },
    { label: "Claim Warranty", to: "/claimWarranty" },
    { label: "Warranty Terms", to: "/WarrantyTerms" },
  ];

  const usefulLinks = [
    { label: "Terms and Conditions", to: "/WarrantyTerms" },
    { label: "Refund & Cancellation", to: "/RefundAndCancellationPolicy" },
    { label: "Privacy Policy", to: "/PrivacyPolicy" },
  ];

  const adminLinks = [
    { label: "Registered Batteries", to: "/adminPannel" },
    { label: "Claim Warranty", to: "/admin-claimwarranty" },
    { label: "Contacts Submission", to: "/admin-contacts" },
    { label: "Update Credentials", to: "/admin-updateCredentials" },
  ];

  return (
    <>
      <footer className="bg-white mt-1">
        <h1 className="text-white justify-center align-center md:flex text-2xl bg-[#14181F] p-4">
          <span className="text-orange-500">AVS Battery </span> - Powering Your
          World, One Charge at a Time.
        </h1>
        <div className="container mx-auto px-4 md:px-8 lg:px-16 mt-8 mb-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 md:gap-6 text-[#171717]">
            {/* Column 1 */}
            <ul className="col-span-2 space-y-2 md:space-y-4">
              <li className="text-2xl md:text-3xl font-bold">
                <span>AVS</span> Battery
              </li>
              <li className="text-sm md:text-base text-gray-600">
                We offer a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
              <li className="flex gap-8 text-base md:text-lg font-medium">
                <a
                  href="tel:7667131756"
                  className="flex items-center text-gray-800 hover:text-orange-600"
                >
                  <i className="fa-solid fa-phone mr-2 md:mr-3"></i> 7667131756
                </a>
                <a
                  href="tel:8294205717"
                  className="flex items-center text-gray-800 hover:text-orange-600"
                >
                  <i className="fa-solid fa-phone mr-2 md:mr-3"></i> 8294205717
                </a>
              </li>
              <li className="text-base md:text-lg font-medium">
                <a
                  href="mailto:Aksuvaindustries@gmail.com"
                  className="flex items-center text-gray-800 hover:text-orange-600"
                >
                  <i className="fa-solid fa-envelope mr-2 md:mr-3"></i>
                  Aksuvaindustries@gmail.com
                </a>
              </li>
              <li className="text-base md:text-lg font-medium">
                <a
                  href="mailto:Aksuvaindustries@gmail.com"
                  className="flex items-center text-gray-800 hover:text-orange-600"
                >
                  <i className="fa-solid fa-building mr-2 md:mr-3"></i> Ramgarh
                  Nonar Baraura Jeep Stand Ramgrah , Kaimur Bihar
                </a>
              </li>
            </ul>
            {isAdmin ? (
              <ul className="space-y-2 md:space-y-4 col-span-1 md:col-span-2 lg:col-span-2 flex flex-col items-center">
                <li className="text-base md:text-lg font-bold uppercase">
                  Admin Links
                </li>
                {adminLinks.map((link) => (
                  <li key={link.to}>
                    <Link
                      to={link.to}
                      className="text-base md:text-lg text-gray-800 hover:text-orange-600"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {/* Company Links */}
                <ul className="space-y-2 md:space-y-4 col-span-1">
                  <li className="text-base md:text-lg font-bold uppercase">
                    Company
                  </li>
                  {companyLinks.map((link) => (
                    <li key={link.to}>
                      <Link
                        to={link.to}
                        className="text-base md:text-lg text-gray-800 hover:text-orange-600"
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>

                {/* Warranty Links */}
                <ul className="space-y-2 md:space-y-4 col-span-1">
                  <li className="text-base md:text-lg font-bold uppercase">
                    Warranty
                  </li>
                  {warrantyLinks.map((link) => (
                    <li key={link.to}>
                      <Link
                        to={link.to}
                        className="text-base md:text-lg text-gray-800 hover:text-orange-600"
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {/* Useful Links */}
            <ul className="space-y-2 md:space-y-4 col-span-1">
              <li className="text-base md:text-lg font-bold uppercase">
                Useful Links
              </li>
              {usefulLinks.map((link) => (
                <li key={link.to}>
                  <Link
                    to={link.to}
                    className="text-base md:text-lg text-gray-800 hover:text-orange-600"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}

              <li className="text-base md:text-lg font-bold uppercase">
                Follow on :
              </li>
              <li className="text-sm md:text-base">
                <div className="flex items-center space-x-6">
                  <a
                    href="/"
                    className="text-[#DE2F58] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
                  >
                    <svg
                      viewBox="0 0 30 30"
                      fill="currentColor"
                      className="h-6"
                    >
                      <circle cx="15" cy="15" r="4" />
                      <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.facebook.com/people/AVS-Battery/61564952854877/?mibextid=qi2Omg&rdid=SUVZAIS8612Qw34d&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F3FdYCgtC2Jv79h7f%2F%3Fmibextid%3Dqi2Omg"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Connect with us on Facebook"
                    className="text-[#3B5999] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5"
                    >
                      <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                    </svg>
                  </a>
                  <a
                    href="/"
                    className="text-[#030303] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      className="h-5 w-5"
                    >
                      <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                    </svg>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center justify-between mt-4 bg-[#171717] py-2">
          <p className="text-sm text-white md:text-base mx-4">
            Copyright © 2024 AVS Batteries. All rights reserved
          </p>
          <a
            href="tel:7970693331"
            className="text-sm text-white md:text-base mx-4 hover:text-orange-600"
          >
            Designed by Tanmay & Team
          </a>
        </div>
      </footer>

      <div
        onClick={scrollToTop}
        className={`fixed bottom-2 right-5 p-3 z-20 bg-orange-500 text-white rounded-full border-3 border-white flex items-center justify-center cursor-pointer transform transition-opacity ${
          goUp ? "opacity-100" : "opacity-0"
        }`}
      >
        <i className="fa-solid fa-angle-up text-xl"></i>
      </div>
    </>
  );
}

export default Footer;
