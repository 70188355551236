import React from "react";
import HeroPages from "../components/HeroPages";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="lg:py-6 bg-gray-50">
        <HeroPages name="Privacy Policy" />
        <div className="container mx-auto px-4 lg:px-8 w-[95%]">
          <div className="bg-white p-6 lg:p-12 rounded-lg shadow-lg space-y-12">
            {/* Privacy Policy */}
            <div className="space-y-8">
              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Introduction
                </h2>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                  At AVS Battery, we respect your privacy and are committed to
                  protecting the personal information you share with us. This
                  Privacy Policy outlines how we collect, use, and safeguard
                  your data when you interact with our website and services.
                </p>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Information We Collect
                </h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl lg:text-2xl font-semibold text-gray-800">
                      Personal Information
                    </h3>
                    <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                      We collect personal information that you provide to us
                      directly, such as your name, email address, phone number,
                      and billing information. This information is collected
                      when you register on our site, place an order, subscribe
                      to our newsletter, or contact us for support.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-xl lg:text-2xl font-semibold text-gray-800">
                      Non-Personal Information
                    </h3>
                    <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                      We may also collect non-personal information, such as your
                      browser type, IP address, and browsing behavior. This
                      information is used to enhance your experience on our
                      website and improve our services.
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  How We Use Your Information
                </h2>
                <div className="space-y-4">
                  <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                    We use the information we collect for various purposes,
                    including:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 text-md lg:text-lg text-gray-700 leading-relaxed">
                    <li>Processing your orders and managing your account.</li>
                    <li>
                      Providing customer support and responding to inquiries.
                    </li>
                    <li>
                      Sending you promotional materials and updates (if you have
                      opted in).
                    </li>
                    <li>Improving our website, products, and services.</li>
                    <li>Analyzing website usage to enhance user experience.</li>
                  </ul>
                </div>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  How We Share Your Information
                </h2>
                <div className="space-y-4">
                  <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                    We do not sell, trade, or otherwise transfer your personal
                    information to third parties, except in the following
                    circumstances:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 text-md lg:text-lg text-gray-700 leading-relaxed">
                    <li>
                      To trusted service providers who assist us in operating
                      our website and business.
                    </li>
                    <li>
                      When required by law to comply with legal obligations or
                      protect our rights.
                    </li>
                    <li>
                      In connection with a merger, acquisition, or sale of all
                      or a portion of our business.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Data Security
                </h2>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                  We take reasonable precautions to protect your personal
                  information from unauthorized access, disclosure, or
                  alteration. However, no method of transmission over the
                  internet or electronic storage is 100% secure, and we cannot
                  guarantee absolute security.
                </p>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Your Rights
                </h2>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                  You have the right to access, update, or delete your personal
                  information at any time. If you wish to exercise these rights,
                  please contact us using the information provided below. You
                  may also opt out of receiving promotional communications from
                  us at any time by following the unsubscribe instructions in
                  our emails.
                </p>
              </div>

              <div className="border-b pb-6">
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Changes to This Privacy Policy
                </h2>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                  We may update this Privacy Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. We will notify you of any significant
                  changes by posting the updated policy on our website.
                </p>
              </div>

              <div>
                <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-gray-900">
                  Contact Us
                </h2>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed">
                  If you have any questions or concerns about this Privacy
                  Policy or how we handle your personal information, please
                  contact us at:
                </p>
                <p className="text-md lg:text-lg text-gray-700 leading-relaxed mt-4">
                  <ul className="col-span-2 space-y-2 md:space-y-4">
                    <li className="text-2xl md:text-3xl font-bold">
                      <span>AVS</span> Battery
                    </li>
                    <li className="text-sm md:text-base text-gray-600">
                      We offer a big range of vehicles for all your driving
                      needs. We have the perfect car to meet your needs.
                    </li>
                    <li className="flex gap-8 text-base md:text-lg font-medium">
                      <a
                        href="tel:7667131756"
                        className="flex items-center text-gray-800 hover:text-orange-600"
                      >
                        <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                        7667131756
                      </a>
                      <a
                        href="tel:8294205717"
                        className="flex items-center text-gray-800 hover:text-orange-600"
                      >
                        <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                        8294205717
                      </a>
                    </li>
                    <li className="text-base md:text-lg font-medium">
                      <a
                        href="mailto:Aksuvaindustries@gmail.com"
                        className="flex items-center text-gray-800 hover:text-orange-600"
                      >
                        <i className="fa-solid fa-envelope mr-2 md:mr-3"></i>{" "}
                        Aksuvaindustries@gmail.com
                      </a>
                    </li>
                    <li className="text-base md:text-lg font-medium">
                      <a
                        href="mailto:Aksuvaindustries@gmail.com"
                        className="flex items-center text-gray-800 hover:text-orange-600"
                      >
                        <i className="fa-solid fa-building mr-2 md:mr-3"></i>
                        Ramgarh Nonar Baraura Jeep Stand Ramgrah , Kaimur Bihar
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
