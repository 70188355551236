import React from "react";
import HeroPages from "../components/HeroPages";

const WarrantyTerms = () => {
  return (
    <>
      <section className="lg:py-6 bg-gray-50">
        <HeroPages name="Warranty Terms and Conditions & FAQ" />
        <div className="container mx-auto px-4 lg:px-8 w-[95%]">
          <div className="bg-white p-6 lg:p-8 rounded-lg space-y-12">
            {/* Warranty Terms and Conditions */}
            <div>
              <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                Warranty Terms and Conditions
              </h2>
              <div className="space-y-4">
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Welcome to AVS Battery. Our batteries come with a
                  comprehensive warranty to ensure your peace of mind. Please
                  read the following terms and conditions carefully.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Warranty Coverage
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Our batteries are warranted to be free from defects in
                  material and workmanship under normal use and service for a
                  period of [Warranty Period] from the date of purchase. This
                  warranty covers any manufacturing defects or failure due to
                  improper assembly of the battery.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Exclusions
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  This warranty does not cover damage caused by misuse, neglect,
                  accidents, or unauthorized alterations. It also does not cover
                  normal wear and tear or consumable parts. The warranty will be
                  void if the battery is used in applications for which it was
                  not designed or if it is damaged due to overcharging, deep
                  discharging, or improper storage.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Claim Process
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  To make a warranty claim, please contact our customer service
                  with your proof of purchase and battery details. Our team will
                  guide you through the process and provide support. You may be
                  required to return the defective battery to us for inspection.
                  If the battery is found to be defective under the terms of
                  this warranty, we will replace it with a new or refurbished
                  battery.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Limitations
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  AVS Battery's liability is limited to the replacement of the
                  defective battery. We are not liable for any incidental or
                  consequential damages arising from the use of our products.
                  This warranty gives you specific legal rights, and you may
                  also have other rights which vary from state to state.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Important Notes
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  It is important to register your battery within 30 days of
                  purchase to activate the warranty. Keep your purchase receipt
                  and warranty card in a safe place. Regular maintenance and
                  proper use will help extend the life of your battery and
                  ensure optimal performance.
                </p>
              </div>
            </div>
            {/* Frequently Asked Questions */}
            <div>
              <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                Frequently Asked Questions
              </h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl lg:text-2xl font-semibold mb-2">
                    How do I properly maintain my AVS battery?
                  </h3>
                  <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                    Regularly check the battery terminals for corrosion, keep
                    the battery clean and dry, and ensure it is fully charged.
                    Avoid deep discharges and store the battery in a cool, dry
                    place when not in use.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl lg:text-2xl font-semibold mb-2">
                    What is the correct way to install an AVS battery?
                  </h3>
                  <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                    Ensure the battery is installed in a well-ventilated area.
                    Connect the positive terminal first, followed by the
                    negative terminal. Secure the battery firmly to prevent
                    movement. Refer to the user manual for detailed installation
                    instructions.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl lg:text-2xl font-semibold mb-2">
                    How should I dispose of my old AVS battery?
                  </h3>
                  <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                    Batteries contain hazardous materials and should not be
                    disposed of in regular trash. Please take your old battery
                    to a certified recycling center or contact our customer
                    service for disposal guidance.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl lg:text-2xl font-semibold mb-2">
                    What should I do if my battery fails during the warranty
                    period?
                  </h3>
                  <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                    If your battery fails during the warranty period, contact
                    our customer service team for assistance. They will guide
                    you through the warranty claim process and help you get a
                    replacement battery if the failure is covered under the
                    warranty terms.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl lg:text-2xl font-semibold mb-2">
                    Can I use my AVS battery in a different type of vehicle?
                  </h3>
                  <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                    Our batteries are designed for specific types of vehicles
                    and applications. Using the battery in a different type of
                    vehicle may void the warranty. Please refer to the user
                    manual or contact our customer service team for
                    compatibility information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WarrantyTerms;
