import { Link } from "react-router-dom";

function HeroPages({ name }) {
  return (
    <section className="mt-16 lg:mt-10 relative flex items-center justify-center bg-cover bg-center h-32 lg:h-48 bg-gray-800">
      <div className="absolute inset-0 bg-[#171717] opacity-50"></div>
      <div className="container mx-auto px-4 relative z-10 text-center">
        <h3 className="text-4xl lg:text-5xl font-semibold text-white mb-4 animate__animated animate__fadeInDown">
          {name}
        </h3>
        <p className="text-md lg:text-lg text-white">
          <Link
            to="/"
            className="hover:text-orange-500 transition duration-300"
          >
            Home
          </Link>
          &nbsp;/&nbsp;
          <span className="font-semibold">{name}</span>
        </p>
      </div>
    </section>
  );
}

export default HeroPages;
