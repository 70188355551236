import Hero from "../components/Hero";
import Highlights from "../components/Highlights";
import PlanBattries from "../components/PlanBattries";
import PickBattery from "../components/PickBattery";
import Banner from "../components/Banner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";

function Home() {
  return (
    <>
      <Hero />
      <Highlights />
      <PlanBattries />
      <PickBattery />
      <Banner />
      <ChooseUs />
      <Testimonials />
      <Faq />
    </>
  );
}

export default Home;
