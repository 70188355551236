import React from "react";
import { Link } from "react-router-dom";

function Highlights() {
  return (
    <div className="relative box-border p-4 mx-auto bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 z-10 shadow-lg rounded-lg overflow-hidden ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 w-[90%]">
        <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl  transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
          <a
            href="https://wa.me/7667131756?text=Thank%20you%20for%20contacting%20AVS%20BATTERY%21%20Our%20representative%20will%20get%20in%20touch%20with%20you%20shortly.%20To%20know%20more%20about%20us%2C%20kindly%20have%20a%20look%20at%20our%20website%20www.avsbattery.in"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
            className="w-full"
          >
            <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
              Partner With Us
            </h3>
            <span className="text-gray-600 text-sm lg:text-base">
              Enjoy lucrative offers & benefits!
            </span>
          </a>
        </aside>
        <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl  transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
          <Link to="/RegistrationPage" className="w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
              Register for Warranty
            </h3>
            <span className="text-gray-600 text-sm lg:text-base">
              Get Paperless Warranty!
            </span>
          </Link>
        </aside>
        <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl  transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
          <Link to="/claimWarranty" className="w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
              Claim Warranty
            </h3>
            <span className="text-gray-600 text-sm lg:text-base">
              Claim warranty for your battery.
            </span>
          </Link>
        </aside>
        <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl  transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
          <Link to="/Batteries" className="w-full">
            <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
              Find Your Battery
            </h3>
            <span className="text-gray-600 text-sm lg:text-base">
              Looking for the right battery for vehicle or home?
            </span>
          </Link>
        </aside>
      </div>
    </div>
  );
}

export default Highlights;
