import { useState } from "react";
import BatteryBox from "./BatteryBox";
import { BATTERY_DATA } from "./BatteryData";

function PickBattery() {
  const [active, setActive] = useState("SecondBattery");
  const [colorBtn, setColorBtn] = useState("btn1");

  const btnID = (id) => {
    setColorBtn(colorBtn === id ? "" : id);
  };

  const coloringButton = (id) => {
    return colorBtn === id
      ? "bg-orange-500 text-white"
      : "bg-gray-200 text-gray-800";
  };

  return (
    <section className="py-12">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col items-center">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Our Battery Selection
            </h2>
            <p className="text-base md:text-lg text-gray-600">
              Choose from a variety of our top-quality batteries to power your
              vehicle reliably.
            </p>
          </div>
          <div className="flex flex-wrap justify-center gap-4 lg:gap-6">
            {/* Pick Battery */}
            <div className="flex flex-wrap justify-center gap-4 md:gap-6">
              {[
                "btn1",
                "btn2",
                "btn3",
                "btn4",
                "btn5",
                "btn6",
                "btn7",
                "btn8",
                "btn9",
                "btn10",
                "btn11",
                "btn12",
                "btn13",
                "btn14",
                "btn15",
              ].map((btn, index) => (
                <button
                  key={btn}
                  className={`py-2 px-4 md:py-3 md:px-6 font-semibold rounded-md transition-colors duration-300 ${coloringButton(
                    btn
                  )}`}
                  onClick={() => {
                    setActive(
                      [
                        "FirstBattery",
                        "SecondBattery",
                        "ThirdBattery",
                        "FourthBattery",
                        "FifthBattery",
                        "SixthBattery",
                        "SeventhBattery",
                        "EighthBattery",
                        "NinethBattery",
                        "TenthBattery",
                        "EleventhBattery",
                        "TwelfthBattery",
                        "ThirteenthBattery",
                        "FourteenthBattery",
                        "FifteenthBattery",
                      ][index]
                    );
                    btnID(btn);
                  }}
                >
                  {
                    [
                      "AVSL-50Ah",
                      "AVSL-70Ah",
                      "AVSL-88Ah",
                      "AVSL-100Ah",
                      "AVSL-125Ah",
                      "AVSL-130Ah",
                      "AVSL-135Ah",
                      "AVSL-140Ah",
                      "AVSL-145Ah",
                      "AVSL-150Ah",
                      "AVSL-180Ah",
                      "AVSL-200Ah",
                      "AVSL-220Ah",
                      "AVSL-230Ah",
                      "AVSL-250Ah",
                    ][index]
                  }
                </button>
              ))}
            </div>
            {active && (
              <BatteryBox
                data={BATTERY_DATA}
                batteryID={[
                  "FirstBattery",
                  "SecondBattery",
                  "ThirdBattery",
                  "FourthBattery",
                  "FifthBattery",
                  "SixthBattery",
                  "SeventhBattery",
                  "EighthBattery",
                  "NinethBattery",
                  "TenthBattery",
                  "EleventhBattery",
                  "TwelfthBattery",
                  "ThirteenthBattery",
                  "FourteenthBattery",
                  "FifteenthBattery",
                ].indexOf(active)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PickBattery;
