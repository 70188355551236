import { useState } from "react";
import BgImage from "../images/1.png"; // Adjust path as needed

function Faq() {
  const [activeQ, setActiveQ] = useState(null);

  const toggleQ = (id) => {
    setActiveQ(activeQ === id ? null : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "max-h-screen py-4" : "max-h-0 py-0";
  };

  const getClassQuestion = (id) => {
    return activeQ === id
      ? "bg-orange-600 text-white shadow-xl"
      : "bg-white text-gray-800";
  };

  return (
    <section className="relative py-8 md:py-2">
      {/* Background Image */}
      <img
        src={BgImage}
        alt="Background"
        className="absolute top-72 left-0 h-54 w-54 object-cover opacity-50"
      />

      {/* FAQ Content */}
      <div className="relative z-10 container mx-auto px-4 md:px-8">
        <div className="text-center mb-2 md:mb-4">
          <h5 className="text-2xl md:text-3xl font-semibold text-gray-800">
            FAQ
          </h5>
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mt-4 md:mt-8 mb-2 md:mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
            Frequently Asked Questions About AVS Batteries: Answers to Common
            Concerns and Inquiries.
          </p>
        </div>

        <div className="w-full md:w-4/5 mx-auto flex flex-col gap-2 md:gap-2">
          {[
            {
              id: "q1",
              question:
                "1. What makes AVS batteries different from other brands?",
              answer:
                "AVS batteries are known for their superior performance and durability. They use advanced technology to provide longer life and better reliability compared to standard batteries. Our batteries are rigorously tested to ensure they meet high-quality standards and offer optimal performance for various applications.",
            },
            {
              id: "q2",
              question:
                "2. How do I know which AVS battery is right for my vehicle?",
              answer:
                "Choosing the right AVS battery depends on your vehicle’s specifications. You should check the battery size, type, and power requirements recommended by your vehicle's manufacturer. Our website provides a battery finder tool to help you select the appropriate battery based on your vehicle’s make and model.",
            },
            {
              id: "q3",
              question: "3. What is the warranty on AVS batteries?",
              answer:
                "AVS batteries come with a comprehensive warranty that varies by model. Most of our batteries are backed by a 2 to 5-year warranty, depending on the type and application. This warranty covers defects in materials and workmanship, ensuring peace of mind for our customers.",
            },
            {
              id: "q4",
              question:
                "4. How should I maintain my AVS battery to ensure optimal performance?",
              answer:
                "To maintain your AVS battery, ensure it is securely mounted and clean. Regularly check the battery terminals for corrosion and clean them if necessary. Avoid deep discharges and ensure your vehicle’s charging system is functioning correctly. Regular maintenance can extend the life of your battery.",
            },
            {
              id: "q5",
              question:
                "5. Can I return or exchange an AVS battery if it’s not the right fit?",
              answer:
                "Yes, AVS offers a flexible return and exchange policy. If the battery is not the correct fit or if you encounter any issues, you can return or exchange it within the specified return period. Please refer to our return policy on our website for detailed instructions and conditions.",
            },
          ].map(({ id, question, answer }) => (
            <div
              key={id}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <div
                onClick={() => toggleQ(id)}
                className={`flex justify-between items-center p-4 md:px-8 cursor-pointer transition-all ${getClassQuestion(
                  id
                )}`}
              >
                <p className="text-md md:text-lg font-semibold">{question}</p>
                <i
                  className={`fa-solid fa-angle-down text-3xl md:text-4xl transition-transform ${
                    activeQ === id ? "rotate-180" : ""
                  }`}
                ></i>
              </div>
              <div
                className={`transition-all overflow-hidden ${getClassAnswer(
                  id
                )}`}
              >
                <p className="px-6 md:px-8 text-base md:text-md text-gray-700">
                  {answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Faq;
