import React from "react";

function ProductRange() {
  return (
    <section className="bg-gradient-to-r from-gray-50 to-gray-100 py-4">
      <div className="container mx-auto px-4">
        <div className="w-[90%] mx-auto">
          <div className="text-center mb-8">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 my-3">
              Our Product Range
            </h2>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 lg:gap-12">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                Solar Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Harness the power of the sun with our efficient and durable
                solar batteries. Perfect for residential, commercial, and
                industrial solar power systems, our batteries ensure you have a
                consistent energy supply, even when the sun isn't shining.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                E-Rickshaw Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Drive into the future with our robust e-rickshaw batteries.
                Designed for maximum performance and longevity, these batteries
                ensure your e-rickshaws run smoothly, providing reliable and
                eco-friendly transportation solutions.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                Tractor Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Keep your tracking devices powered with our reliable tractor
                batteries. Whether for personal or business use, our batteries
                ensure that your tracking systems remain operational, providing
                peace of mind and security.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                Pick-Up Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Equip your pick-up vehicles with our high-performance batteries.
                Built to withstand tough conditions, our pick-up batteries offer
                long-lasting power and reliability for all your transport needs.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                Tempu Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Ensure uninterrupted service with our durable tempu batteries.
                Designed for heavy usage and frequent stops, our batteries
                provide the reliability and endurance needed for efficient
                transportation.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                House Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Power your home with our advanced house batteries. Whether for
                backup power or off-grid living, our batteries offer dependable
                and long-lasting energy solutions, ensuring your home is always
                powered.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-2xl lg:text-3xl font-semibold mb-4 text-gray-800">
                Shop Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Keep your business running smoothly with our shop batteries.
                Ideal for a variety of commercial applications, our batteries
                provide consistent power, helping you avoid downtime and
                maintain productivity.
              </p>
            </div>
          </div>

          <div className="mt-8 p-6 text-center">
            <p className="text-base lg:text-lg text-gray-700 leading-relaxed">
              At AVS Battery, we take pride in manufacturing all these types of
              batteries to meet the diverse needs of our customers. Our products
              are built with the latest technology, ensuring they are efficient,
              durable, and environmentally friendly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductRange;
