import React, { useState } from "react";
import HeroPages from "../components/HeroPages";
import axios from "axios";
import { toast } from "react-toastify";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "", // Added subject field
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return; // Prevent multiple submissions

    setLoading(true);
    try {
      await axios.post(
        "https://avs-battery.onrender.com/api/contact",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Message sent successfully.");
      setFormData({
        name: "",
        email: "",
        subject: "", // Reset subject field
        message: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again.");
      setFormData({
        name: "",
        email: "",
        subject: "", // Reset subject field
        message: "",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="lg:py-6">
        <HeroPages name="Contact Us" />
        <div className="container mx-auto px-4 lg:px-8 w-[95%]">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 py-12 bg-cover bg-center bg-no-repeat rounded-lg"
            style={{
              backgroundImage: "url('/src/images/banners/bg-contact.png')",
            }}
          >
            <div className="flex flex-col text-[#171717] max-w-4xl mx-auto p-6 bg-white bg-opacity-80 rounded-lg">
              <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-8">
                Feel free to reach out to us:
              </h2>
              <p className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8">
                Have questions or need assistance? Whether you want to inquire
                about our AVS LIGER batteries, discuss distributorship
                opportunities, or simply reach out to us for support, we're here
                to help. Please fill out the form below, and our team will get
                back to you as soon as possible.
              </p>

              <ul className="col-span-2 space-y-2 md:space-y-4">
                <li className="text-base md:text-lg font-medium">
                  <a
                    href="tel:7667131756"
                    className="flex items-center text-gray-800 hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>
                    7667131756
                  </a>
                </li>
                <li className="text-base md:text-lg font-medium">
                  <a
                    href="tel:8294205717"
                    className="flex items-center text-gray-800 hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>
                    8294205717
                  </a>
                </li>
                <li className="text-base md:text-lg font-medium">
                  <a
                    href="mailto:Aksuvaindustries@gmail.com"
                    className="flex items-center text-gray-800 hover:text-orange-600"
                  >
                    <i className="fa-solid fa-envelope mr-2 md:mr-3"></i>
                    Aksuvaindustries@gmail.com
                  </a>
                </li>
                <li className="text-base md:text-lg font-medium">
                  <a
                    href="mailto:Aksuvaindustries@gmail.com"
                    className="flex items-center text-gray-800 hover:text-orange-600"
                  >
                    <i className="fa-solid fa-building mr-2 md:mr-3"></i>
                    Ramgarh Nonar Baraura Jeep Stand Ramgrah, Kaimur Bihar
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col p-6 bg-white bg-opacity-80 rounded-lg shadow-md">
              <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Full Name <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder='E.g: "Joe Shmoe"'
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Email <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="youremail@example.com"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Subject <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    required
                    placeholder="Subject of your message"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Message <span className="text-orange-500">*</span>
                  </label>
                  <textarea
                    name="message"
                    required
                    placeholder="Your message..."
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.message}
                    onChange={handleChange}
                    rows="6"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className={`bg-orange-500 py-3 px-6 text-md lg:text-lg text-white font-semibold rounded-lg shadow-lg transition w-full ${
                    loading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-orange-600"
                  }`}
                >
                  {loading ? (
                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                  ) : (
                    <i className="fa-solid fa-envelope mr-2"></i>
                  )}
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsPage;
