import React from "react";
import HeroPages from "../components/HeroPages";
import Inverter from "../images/3.png";
import ERickshaw from "../images/2.png";
import TractorBattery from "../images/1.png";

function Batteries() {
  return (
    <>
      <section className="lg:pt-6 bg-gradient-to-r from-gray-100 to-gray-200">
        <HeroPages name="AVS Batteries" />
        <div className="container mx-auto p-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 py-16">
          {[
            {
              img: Inverter,
              model: "AVSL-250AH",
              details: [
                "Capacity- 250Ah",
                "Price- ₹24100",
                "Warranty- 72 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-250AH",
              details: [
                "Capacity- 250Ah",
                "Price- ₹20400",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-230AH",
              details: [
                "Capacity- 230Ah",
                "Price- ₹20400",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-230AH",
              details: [
                "Capacity- 230Ah",
                "Price- ₹19130",
                "Warranty- 42 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-220AH",
              details: [
                "Capacity- 220Ah",
                "Price- ₹18050",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-220AH",
              details: [
                "Capacity- 220Ah",
                "Price- ₹17600",
                "Warranty- 42 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-200AH",
              details: [
                "Capacity- 200Ah",
                "Price- ₹16700",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-200AH",
              details: [
                "Capacity- 200Ah",
                "Price- ₹15900",
                "Warranty- 42 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-180AH",
              details: [
                "Capacity- 180Ah",
                "Price- ₹17300",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-180AH",
              details: [
                "Capacity- 180Ah",
                "Price- ₹15350",
                "Warranty- 36 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-150AH",
              details: [
                "Capacity- 150Ah",
                "Price- ₹13000",
                "Warranty- 60 months",
                "Application- Inverter",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-150AH",
              details: [
                "Capacity- 150Ah",
                "Price- ₹12500",
                "Warranty- 42 months",
                "Application- Inverter",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-150AH",
              details: [
                "Capacity- 150Ah",
                "Price- ₹13400",
                "Warranty- 15 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-150AH",
              details: [
                "Capacity- 150Ah",
                "Price- ₹12700",
                "Warranty- 15 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-145AH",
              details: [
                "Capacity- 145Ah",
                "Price- ₹12500",
                "Warranty- 18 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-140AH",
              details: [
                "Capacity- 140Ah",
                "Price- ₹11700",
                "Warranty- 15 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: Inverter,
              model: "AVSL-135AH",
              details: [
                "Capacity- 135Ah",
                "Price- ₹10200",
                "Warranty- 36 months",
                "Application- Inverter",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-135AH",
              details: [
                "Capacity- 135Ah",
                "Price- ₹11200",
                "Warranty- 15 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-135AH",
              details: [
                "Capacity- 135Ah",
                "Price- ₹10300",
                "Warranty- 12 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-130AH",
              details: [
                "Capacity- 130Ah",
                "Price- 9000",
                "Warranty- 12 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: ERickshaw,
              model: "AVSL-125AH",
              details: [
                "Capacity- 125Ah",
                "Price- ₹8700",
                "Warranty- 9 months",
                "Application- E Rickshaw",
              ],
            },
            {
              img: TractorBattery,
              model: "AVSL-100AH",
              details: [
                "Capacity- 100Ah",
                "Price- ₹7856",
                "Warranty- 42 months",
                "Application- Tractor Battery",
              ],
            },
            {
              img: TractorBattery,
              model: "AVSL-88AH",
              details: [
                "Capacity- 88Ah",
                "Price- ₹7379",
                "Warranty- 36 months",
                "Application- Tractor Battery",
              ],
            },
            {
              img: TractorBattery,
              model: "AVSL-70AH",
              details: [
                "Capacity- 70Ah",
                "Price- ₹6352",
                "Warranty- 36 months",
                "Application- Tractor Battery",
              ],
            },
            {
              img: TractorBattery,
              model: "AVSL-50AH",
              details: [
                "Capacity- 50Ah",
                "Price- ₹4653",
                "Warranty- 42 months",
                "Application- Tractor Battery",
              ],
            },
          ].map((battery, index) => (
            <div
              key={index}
              className="bg-white rounded-lg overflow-hidden shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl"
            >
              <div className="w-full h-48 overflow-hidden relative group">
                <img
                  src={battery.img}
                  alt={`battery_img_${index}`}
                  className="w-full h-full object-contain transform group-hover:scale-110 transition duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#171717] opacity-50"></div>
                <div className="absolute bottom-0 left-0 p-4">
                  <h3 className="text-xl font-bold text-white">
                    {battery.model}
                  </h3>
                </div>
              </div>
              <div className="p-6 flex flex-col text-gray-700">
                <div className="grid grid-cols-1 gap-4 mb-6 border-b border-gray-300 pb-6">
                  {battery.details.map((detail, i) => (
                    <span key={i} className="text-md font-medium">
                      <i className="fa-solid fa-asterisk mr-2 text-orange-500"></i>
                      {detail}
                    </span>
                  ))}
                </div>
                <a
                  href="https://wa.me/7667131756"
                  className="flex items-center text-gray-800 hover:text-orange-600 justify-center"
                >
                  <button className="bg-orange-500 x-full text-white py-2 px-6 rounded-lg shadow-lg border-2 border-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out">
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>
                    Order&nbsp;Now
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-gray-900 text-white text-center py-6 relative">
          <div className="absolute inset-0 bg-[#171717] opacity-50"></div>
          <div className="relative z-10">
            <div className="container mx-auto">
              <h2 className="text-2xl md:text-3xl font-bold">
                Contact us for more information
              </h2>
              <span className="flex items-center justify-center text-xl mt-4">
                <li className="flex gap-8 text-base md:text-lg font-medium">
                  <a
                    href="tel:7667131756"
                    className="flex items-center text-white hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                    7667131756
                  </a>
                  <a
                    href="tel:8294205717"
                    className="flex items-center text-white hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                    8294205717
                  </a>
                </li>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Batteries;
