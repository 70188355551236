import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AdminWarrantyClaimsPage = () => {
  const [claims, setClaims] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("createdAt");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchClaims = async () => {
      try {
        const response = await axios.get(
          // "http://localhost:5000/api/warranty-claim",
          "https://avs-battery.onrender.com/api/warranty-claim",
          {
            headers: { "x-auth-token": token },
          }
        );
        setClaims(response.data);
      } catch (error) {
        toast.error("Failed to fetch warranty claims.");
      }
    };

    fetchClaims();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSort = (e) => {
    setSort(e.target.value);
  };

  const filteredClaims = claims
    .filter((claim) => claim.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => (a[sort] > b[sort] ? 1 : -1));

  return (
    <div className=" w-full mx-auto px-4 md:px-8 mt-16 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold text-gray-900 mb-6 pt-4 flex justify-center">
        Admin Warranty Claims
      </h1>
      <div className="mb-6 flex flex-col md:flex-row gap-4 md:gap-6">
        <input
          type="text"
          placeholder="Search by name"
          className="border border-gray-300 px-4 py-2 rounded-lg w-full md:w-1/3 text-lg shadow-sm focus:ring-2 focus:ring-orange-500"
          value={search}
          onChange={handleSearch}
        />
        <select
          className="border border-gray-300 px-4 py-2 rounded-lg w-full md:w-1/3 text-lg shadow-sm focus:ring-2 focus:ring-orange-500"
          value={sort}
          onChange={handleSort}
        >
          <option value="createdAt">Sort by Date</option>
          <option value="name">Sort by Name</option>
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-300">
              <th className="py-4 px-6 text-left text-gray-700">Name</th>
              <th className="py-4 px-6 text-left text-gray-700">Email</th>
              <th className="py-4 px-6 text-left text-gray-700">Phone</th>
              <th className="py-4 px-6 text-left text-gray-700">
                Battery Serial
              </th>
              <th className="py-4 px-6 text-left text-gray-700">
                Issue Description
              </th>
              <th className="py-4 px-6 text-left text-gray-700">Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredClaims.map((claim) => (
              <tr
                key={claim._id}
                className="border-b border-gray-300 hover:bg-gray-50"
              >
                <td className="py-3 px-6 text-gray-800">{claim.name}</td>
                <td className="py-3 px-6 text-gray-800">
                  <div className="flex items-center">
                    <span>{claim.email}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-gray-800">
                  <div className="flex items-center">
                    <span>{claim.phone}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-gray-800">
                  <div className="flex items-center">
                    <span>{claim.batterySerial}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-gray-800">
                  {claim.issueDescription}
                </td>
                <td className="py-3 px-6 text-gray-800">
                  {new Date(claim.createdAt).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminWarrantyClaimsPage;
