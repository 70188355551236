import { Link } from "react-router-dom";
import BgShape from "../images/hero-bg.png";
import HeroBattery from "../images/hero.png";

function Hero() {
  return (
    <>
      <section
        id="home"
        className="relative w-full  pt-8 bg-gray-100 overflow-hidden mt-16"
      >
        <img
          src={BgShape}
          alt="Background Shape"
          className="absolute top-0 right-0 z-10 block"
        />
        <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center justify-between px-4 lg:px-8">
          <div className="relative z-20 flex flex-col text-[#171717] max-w-2xl lg:max-w-3xl lg:items-start items-center text-center lg:text-left">
            <h4 className="text-xl lg:text-3xl font-semibold my-4">
              Power That Never Lets You Down
            </h4>
            <h1 className="text-3xl lg:text-5xl font-bold mb-6 leading-2">
              Save <span className="text-orange-500">big</span> with our{" "}
              <span className="text-orange-500">AVS BATTERY</span> - TALL
              TUBULAR BATTERY
            </h1>
            <p className="text-lg lg:text-xl text-gray-700 mb-12">
              MADE IN INDIA. Unbeatable prices, more miles, flexible pick-up
              options and much more.
            </p>
            <div className="flex gap-4 lg:gap-8 mb-6 flex-col lg:flex-row items-center lg:items-start">
              <Link
                className="bg-orange-500 text-white py-3 px-6 rounded-lg shadow-lg border-2 border-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out"
                to="/Batteries"
              >
                Find Your Battery
                <i className="fa-solid fa-circle-check ml-2"></i>
              </Link>
              <Link
                className="bg-[#171717] text-white py-3 px-6 rounded-lg border-2 border-[#171717] hover:bg-transparent hover:text-[#171717] transition duration-300 ease-in-out"
                to="/about"
              >
                Learn More &nbsp;
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </div>
          </div>
          <img
            src={HeroBattery}
            alt="Battery"
            className="relative z-10 w-full lg:w-2/3 mt-2 sm:mt-0"
          />
        </div>
      </section>
    </>
  );
}

export default Hero;
