import Inverter from "../images/3.png";
import ERickshaw from "../images/2.png";
import TractorBattery from "../images/1.png";
// export const BATTERY_DATA = [
//   [
//     {
//       name: "AVSL-250AH",
//       price: "24100",
//       img: ERickshaw,
//       capacity: "250AH",
//       voltage: "12V",
//       weight: "5kg",
//       dimensions: "196x130x154mm",
//       warranty: "60 months",
//       application: "All",
//     },
//   ],
// ];

export const BATTERY_DATA = [
  [
    {
      img: TractorBattery,
      model: "AVSL-50AH",
      Capacity: "50Ah",
      Price: "₹4653",
      Warranty: "42 months",
      Application: "Tractor Battery",
    },
  ],
  [
    {
      img: TractorBattery,
      model: "AVSL-70AH",
      Capacity: "70Ah",
      Price: "₹6352",
      Warranty: "36 months",
      Application: "Tractor Battery",
    },
  ],
  [
    {
      img: TractorBattery,
      model: "AVSL-88AH",
      Capacity: "88Ah",
      Price: "₹7379",
      Warranty: "36 months",
      Application: "Tractor Battery",
    },
  ],
  [
    {
      img: TractorBattery,
      model: "AVSL-100AH",
      Capacity: "100Ah",
      Price: "₹7856",
      Warranty: "42 months",
      Application: "Tractor Battery",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-125AH",
      Capacity: "125Ah",
      Price: "₹8700",
      Warranty: "9 months",
      Application: "E Rickshaw",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-130AH",
      Capacity: "130Ah",
      Price: "₹9000",
      Warranty: "12 months",
      Application: "E Rickshaw",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-135AH",
      Capacity: "135Ah",
      Price: "₹10300",
      Warranty: "12 months",
      Application: "E Rickshaw",
    },
    {
      img: ERickshaw,
      model: "AVSL-135AH",
      Capacity: "135Ah",
      Price: "₹11200",
      Warranty: "15 months",
      Application: "E Rickshaw",
    },
    {
      img: Inverter,
      model: "AVSL-135AH",
      Capacity: "135Ah",
      Price: "₹10200",
      Warranty: "36 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-140AH",
      Capacity: "140Ah",
      Price: "₹11700",
      Warranty: "15 months",
      Application: "E Rickshaw",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-145AH",
      Capacity: "145Ah",
      Price: "₹12500",
      Warranty: "18 months",
      Application: "E Rickshaw",
    },
  ],
  [
    {
      img: ERickshaw,
      model: "AVSL-150AH",
      Capacity: "150Ah",
      Price: "₹12700",
      Warranty: "15 months",
      Application: "E Rickshaw",
    },
    {
      img: ERickshaw,
      model: "AVSL-150AH",
      Capacity: "150Ah",
      Price: "₹13400",
      Warranty: "15 months",
      Application: "E Rickshaw",
    },
    {
      img: Inverter,
      model: "AVSL-150AH",
      Capacity: "150Ah",
      Price: "₹12500",
      Warranty: "42 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-150AH",
      Capacity: "150Ah",
      Price: "₹13000",
      Warranty: "60 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: Inverter,
      model: "AVSL-180AH",
      Capacity: "180Ah",
      Price: "₹15350",
      Warranty: "36 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-180AH",
      Capacity: "180Ah",
      Price: "₹17300",
      Warranty: "60 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: Inverter,
      model: "AVSL-200AH",
      Capacity: "200Ah",
      Price: "₹15900",
      Warranty: "42 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-200AH",
      Capacity: "200Ah",
      Price: "₹16700",
      Warranty: "60 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: Inverter,
      model: "AVSL-220AH",
      Capacity: "220Ah",
      Price: "₹17600",
      Warranty: "42 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-220AH",
      Capacity: "220Ah",
      Price: "₹18050",
      Warranty: "60 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: Inverter,
      model: "AVSL-230AH",
      Capacity: "230Ah",
      Price: "₹19130",
      Warranty: "42 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-230AH",
      Capacity: "230Ah",
      Price: "₹20400",
      Warranty: "60 months",
      Application: "Inverters",
    },
  ],
  [
    {
      img: Inverter,
      model: "AVSL-250AH",
      Capacity: "250Ah",
      Price: "₹20400",
      Warranty: "60 months",
      Application: "Inverters",
    },
    {
      img: Inverter,
      model: "AVSL-250AH",
      Capacity: "250Ah",
      Price: "₹24100",
      Warranty: "72 months",
      Application: "Inverters",
    },
  ],
];
