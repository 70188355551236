import About from "./Pages/About";
import Home from "./Pages/Home";
import Navbar from "../src/components/Navbar";
import { Route, Routes } from "react-router-dom";
import Batteries from "./Pages/Batteries";
import Gallery from "./Pages/Gallery";
import TestimonialsPage from "./Pages/TestimonialsPage";
import Contact from "./Pages/Contact";
import Footer from "./components/Footer";
import RegistrationPage from "./Pages/RegistrationPage";
import ViewRegistration from "./Pages/ViewRegistration";
import ClaimWarranty from "./Pages/ClaimWarranty";
import WarrantyTerms from "./Pages/WarrantyTerms";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundAndCancellationPolicy from "./Pages/RefundAndCancellation";
import Login from "./admin/Login";
import PrivateRoute from "./admin/PrivateRoute";
import AdminContacts from "./admin/AdminContact";
import UpdateAdminCredentials from "./admin/UpdateAdminCredentials";
import AdminPanel from "./admin/AdminPanel";
import AdminWarrantyClaimsPage from "./admin/AdminWarrantyClaimsPage";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="Batteries" element={<Batteries />} />
        <Route path="Gallery" element={<Gallery />} />
        <Route path="testimonials" element={<TestimonialsPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="RegistrationPage" element={<RegistrationPage />} />
        <Route path="ViewRegistration" element={<ViewRegistration />} />
        <Route path="claimWarranty" element={<ClaimWarranty />} />
        <Route path="WarrantyTerms" element={<WarrantyTerms />} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route
          path="RefundAndCancellationPolicy"
          element={<RefundAndCancellationPolicy />}
        />
        <Route path="/AdminLogin" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/adminPannel" element={<AdminPanel />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/admin-claimwarranty"
            element={<AdminWarrantyClaimsPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/admin-contacts" element={<AdminContacts />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/admin-updateCredentials"
            element={<UpdateAdminCredentials />}
          />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
