import React, { useState } from "react";
import HeroPages from "../components/HeroPages";

const ViewRegistrationPage = () => {
  const [formData, setFormData] = useState({
    phone: "",
    batterySerial: "",
  });
  const [result, setResult] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchRegistration = async (type, value) => {
    try {
      const response = await fetch(
        `https://avs-battery.onrender.com/api/register/view?${type}=${value}`
        // `http://localhost:5000/api/register/view?${type}=${value}`
      );
      const data = await response.json();
      console.log("Fetched Data:", data); // Check this in the browser's console
      if (response.ok) {
        setResult(data);
        setShowNotification(true);
      } else {
        setResult({ error: "No results found." });
        setShowNotification(true);
      }
    } catch (error) {
      console.error("Error fetching registration:", error);
      setResult({ error: "An error occurred. Please try again later." });
      setShowNotification(true);
    }
  };

  const handleMobileSubmit = (e) => {
    e.preventDefault();
    fetchRegistration("phone", formData.phone);
    setFormData({ ...formData, phone: "" }); // Clear input field
  };

  const handleSerialSubmit = (e) => {
    e.preventDefault();
    fetchRegistration("batterySerial", formData.batterySerial);
    setFormData({ ...formData, batterySerial: "" }); // Clear input field
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      <section className="lg:py-6 bg-gray-50">
        <HeroPages name="View Registration" />
        <div className="container mx-auto px-4 lg:px-8 w-[95%]">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 py-12 bg-cover bg-center bg-no-repeat rounded-lg"
            style={{
              backgroundImage:
                "url('/src/images/banners/bg-view-register.png')",
            }}
          >
            <div className="flex flex-col text-[#171717] max-w-4xl mx-auto p-6 bg-white bg-opacity-80 rounded-lg">
              <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-8">
                Check Your Battery Registration
              </h2>
              <p className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8">
                Enter your mobile number or battery serial number to check your
                battery registration status. Please provide accurate details to
                ensure a smooth and efficient process.
              </p>
              <ul className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8 list-disc list-inside">
                <li>High performance and long lifespan</li>
                <li>Comprehensive warranty coverage</li>
                <li>24/7 customer support</li>
                <li>Eco-friendly and sustainable</li>
              </ul>
            </div>
            <div className="flex flex-col p-6 bg-white bg-opacity-80 rounded-lg shadow-md space-y-8">
              {/* Notification Display */}
              {showNotification && (
                <div className="w-full p-4 bg-[#1B2027] text-white rounded-lg shadow-lg transition-transform transform animate-slide-down">
                  <div className="flex justify-between items-center">
                    <div>
                      {result?.error ? (
                        <p className="text-red-500">{result.error}</p>
                      ) : (
                        <div>
                          <h3 className="text-xl font-semibold">
                            Registration Details:
                          </h3>
                          <p>
                            <strong>Name:</strong> {result?.name}
                          </p>
                          <p>
                            <strong>Email:</strong> {result?.email}
                          </p>
                          <p>
                            <strong>Mobile Number:</strong> {result?.phone}
                          </p>
                          <p>
                            <strong>Purchase Date:</strong>{" "}
                            {result?.purchaseDate}
                          </p>
                          <p>
                            <strong>Warranty Period:</strong>{" "}
                            {result?.warrantyPeriod}
                          </p>
                          <p>
                            <strong>Battery Serial:</strong>{" "}
                            {result?.batterySerial}
                          </p>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={handleCloseNotification}
                      className="ml-4 p-1 text-gray-300 hover:text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              <form
                className="flex flex-col space-y-4"
                onSubmit={handleMobileSubmit}
              >
                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Mobile Number <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    required
                    placeholder="Enter your mobile number"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-orange-500 py-3 px-6 text-md lg:text-lg text-white font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition w-full"
                >
                  Check Registration by Mobile
                </button>
              </form>
              <div className="flex items-center justify-center text-gray-700">
                <hr className="border-t border-gray-300 w-full" />
                <span className="px-4 text-md lg:text-lg">or</span>
                <hr className="border-t border-gray-300 w-full" />
              </div>
              <form
                className="flex flex-col space-y-4"
                onSubmit={handleSerialSubmit}
              >
                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Battery Serial Number{" "}
                    <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="batterySerial"
                    required
                    placeholder="Enter your battery serial number"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.batterySerial}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-orange-500 py-3 px-6 text-md lg:text-lg text-white font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition w-full"
                >
                  Check Registration by Serial Number
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewRegistrationPage;
