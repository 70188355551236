import Img1 from "../images/testimonials/t1.jpg";
import Img2 from "../images/testimonials/t2.jpg";
import Img3 from "../images/testimonials/t3.jpg";

function Testimonials() {
  return (
    <section className="bg-gray-100 py-4 md:py-8 lg:py-12 text-gray-900">
      <div className="container mx-auto px-4 lg:px-12">
        <div className="flex flex-col items-center">
          <div className="text-center mb-8 max-w-[90vw] mx-auto">
            <h4 className="text-2xl md:text-3xl font-semibold mb-2 md:mb-4">
              What Our Clients Say
            </h4>
            <h2 className="text-4xl md:text-5xl font-bold mb-4 md:mb-6">
              Customer Testimonials
            </h2>
            <p className="text-lg md:text-lg text-gray-700 leading-relaxed">
              Hear from our satisfied customers who have experienced the
              excellence of AVS batteries. Read their testimonials to understand
              the superior performance and reliability of our products.
            </p>
          </div>

          <div className="flex flex-wrap gap-8 justify-center px-4 lg:px-12">
            {/* Testimonial 1 */}
            <div className="relative bg-white shadow-xl w-full sm:w-[80%] md:w-[48%] lg:w-[30%] p-8 md:p-12 mb-8 transition-transform transform hover:scale-105 duration-300">
              <span className="absolute text-orange-500 text-4xl md:text-6xl bottom-6 right-6 hidden md:block">
                <i className="fa-solid fa-quote-right"></i>
              </span>
              <p className="text-md md:text-lg font-normal mb-6">
                "AVS batteries have completely transformed the performance of my
                vehicle. I couldn't be happier with the reliability and power
                they deliver. Highly recommend to anyone in need of quality
                batteries!"
              </p>
              <div className="flex items-center mt-6">
                <img
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4 object-cover"
                  src={Img1}
                  alt="user_img"
                />
                <div>
                  <h4 className="text-lg md:text-lg font-semibold">
                    Ashutosh Kumar Singh
                  </h4>
                  <p className="text-sm md:text-base text-gray-600">Bihar</p>
                </div>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="relative bg-white shadow-xl w-full sm:w-[80%] md:w-[48%] lg:w-[30%] p-8 md:p-12 mb-8 transition-transform transform hover:scale-105 duration-300">
              <span className="absolute text-orange-500 text-4xl md:text-6xl bottom-6 right-6 hidden md:block">
                <i className="fa-solid fa-quote-right"></i>
              </span>
              <p className="text-md md:text-lg font-normal mb-6">
                "The quality of AVS batteries is top-notch. They offer
                incredible longevity and performance. I've switched all my
                vehicles to AVS and couldn't be more satisfied."
              </p>
              <div className="flex items-center mt-6">
                <img
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4 object-cover"
                  src={Img2}
                  alt="user_img"
                />
                <div>
                  <h4 className="text-lg md:text-lg font-semibold">
                    Sunil Kumar Singh
                  </h4>
                  <p className="text-sm md:text-base text-gray-600">Kaimur</p>
                </div>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="relative bg-white shadow-xl w-full sm:w-[80%] md:w-[48%] lg:w-[30%] p-8 md:p-12 mb-8 transition-transform transform hover:scale-105 duration-300">
              <span className="absolute text-orange-500 text-4xl md:text-6xl bottom-6 right-6 hidden md:block">
                <i className="fa-solid fa-quote-right"></i>
              </span>
              <p className="text-md md:text-lg font-normal mb-6">
                "I've been using AVS batteries for years and they always exceed
                my expectations. Reliable, durable, and great performance!"
              </p>
              <div className="flex items-center mt-6">
                <img
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4 object-cover"
                  src={Img3}
                  alt="user_img"
                />
                <div>
                  <h4 className="text-lg md:text-lg font-semibold">
                    Amit Kumar
                  </h4>
                  <p className="text-sm md:text-base text-gray-600">Buxar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
