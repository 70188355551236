import { Link } from "react-router-dom";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";

function ChooseUs() {
  return (
    <section className="bg-white py-8 bg-cover bg-no-repeat bg-[url('/src/images/chooseUs/bg.png')] bg-[position:-225px_255px]">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center">
          {/* <img
            className="w-full max-w-[60vw] h-auto rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300"
            src={MainImg}
            alt="main_img"
          /> */}
          <div className="flex flex-col lg:flex-row justify-between mt-12 w-full max-w-[90vw]">
            {/* Left Section */}
            <div className="flex flex-col lg:w-1/2 text-center lg:text-left gap-6 mb-12 lg:mb-0 lg:pr-6">
              <h4 className="text-2xl lg:text-4xl font-semibold text-gray-800 mb-2">
                Why Choose Us
              </h4>
              <h2 className="text-3xl lg:text-5xl font-bold text-gray-900 leading-tight mb-6">
                Best Valued Deals You Will Ever Find
              </h2>
              <p className="text-base lg:text-lg text-gray-600 leading-relaxed mb-6">
                Discover the best deals you'll ever find with our unbeatable
                offers. We're dedicated to providing you with the best value for
                your money, so you can enjoy top-quality services and products
                without breaking the bank. Our deals are designed to give you
                the ultimate experience, so don't miss out on your chance to
                save big.
              </p>
              <Link
                to="/about"
                className="bg-red-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md border-2 border-red-600 hover:bg-red-500 hover:shadow-lg transition-colors duration-300 ease-in-out"
              >
                Find Details &nbsp;
                <i className="fa-solid fa-angle-right"></i>
              </Link>
            </div>

            {/* Right Section */}
            <div className="flex flex-col gap-8 lg:gap-12 lg:w-1/2 lg:pl-6">
              <div className="flex items-start gap-4 lg:gap-6 transition-transform transform hover:scale-105 duration-300">
                <img
                  className="w-20 h-20 lg:w-24 lg:h-24 object-cover"
                  src={Box1}
                  alt="high-performance"
                />
                <div className="flex flex-col gap-2">
                  <h4 className="text-xl lg:text-3xl font-semibold text-gray-800">
                    High Performance
                  </h4>
                  <p className="text-sm lg:text-base text-gray-600 leading-relaxed">
                    Experience unmatched performance with our state-of-the-art
                    batteries designed for efficiency and reliability.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 lg:gap-6 transition-transform transform hover:scale-105 duration-300">
                <img
                  className="w-20 h-20 lg:w-24 lg:h-24 object-cover"
                  src={Box2}
                  alt="all-inclusive-pricing"
                />
                <div className="flex flex-col gap-2">
                  <h4 className="text-xl lg:text-3xl font-semibold text-gray-800">
                    All Inclusive Pricing
                  </h4>
                  <p className="text-sm lg:text-base text-gray-600 leading-relaxed">
                    Get everything you need in one convenient, transparent price
                    with our all-inclusive pricing policy.
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-4 lg:gap-6 transition-transform transform hover:scale-105 duration-300">
                <img
                  className="w-20 h-20 lg:w-24 lg:h-24 object-cover"
                  src={Box3}
                  alt="no-hidden-charges"
                />
                <div className="flex flex-col gap-2">
                  <h4 className="text-xl lg:text-3xl font-semibold text-gray-800">
                    No Hidden Charges
                  </h4>
                  <p className="text-sm lg:text-base text-gray-600 leading-relaxed">
                    Enjoy peace of mind with our no hidden charges policy. We
                    believe in transparent and honest pricing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChooseUs;
