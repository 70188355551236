import HeroPages from "../components/HeroPages";
import PlanBattries from "../components/PlanBattries";
import WhyChooseUs from "../components/WhyChooseUs";
import ProductRange from "../components/ProductRange";
import AboutMain from "../images/3.png";
import Director1 from "../images/d1.jpg";
import Director2 from "../images/d2.jpg";

function About() {
  return (
    <>
      <section className="bg-gray-100 lg:py-6">
        <HeroPages name="About AVS Battery" />
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 lg:mt-24 mx-auto">
            <img
              className="w-full lg:w-1/2 rounded-lg shadow-lg transition-transform transform hover:scale-105 max-h-96"
              src={AboutMain}
              alt="About Company"
            />
            <div className="flex flex-col text-[#171717] animate__animated animate__fadeIn">
              <h3 className="text-3xl lg:text-4xl font-semibold mb-3">
                About AVS Batteries
              </h3>
              <h2 className="text-4xl lg:text-6xl font-bold leading-tight mb-6">
                Powering Your Journey with Excellence
              </h2>
              <p className="text-base lg:text-lg text-gray-700 leading-relaxed mb-4">
                Welcome to AVS Battery, where innovation meets reliability. AVS
                BATTERY was started in 21 January 2021. We are a pioneering
                battery manufacturing company dedicated to providing
                high-quality energy solutions for a wide range of applications.
                Our commitment to excellence and sustainable energy drives us to
                produce batteries that empower lives and businesses every day.
              </p>
              <p className="text-base lg:text-lg text-gray-700 leading-relaxed mb-2">
                At AVS Batteries, we are dedicated to providing high-quality
                battery solutions that ensure reliable performance and
                long-lasting power. Our extensive range of batteries caters to
                various needs, from automotive to industrial applications.
                Discover how our innovative products can keep you moving
                forward.
              </p>
              {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8">
                <div className="flex flex-col items-center bg-white p-4 lg:p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                  <img
                    src={Box1}
                    alt="Battery Types"
                    className="w-16 lg:w-24 mb-2 lg:mb-4"
                  />
                  <h4 className="text-3xl lg:text-4xl font-bold mb-1 lg:mb-2">
                    30
                  </h4>
                  <p className="text-base lg:text-lg text-gray-700">
                    Battery Types
                  </p>
                </div>
                <div className="flex flex-col items-center bg-white p-4 lg:p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                  <img
                    src={Box2}
                    alt="Retail Outlets"
                    className="w-16 lg:w-24 mb-2 lg:mb-4"
                  />
                  <h4 className="text-3xl lg:text-4xl font-bold mb-1 lg:mb-2">
                    50
                  </h4>
                  <p className="text-base lg:text-lg text-gray-700">
                    Retail Outlets
                  </p>
                </div>
                <div className="flex flex-col items-center bg-white p-4 lg:p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                  <img
                    src={Box3}
                    alt="Service Centers"
                    className="w-16 lg:w-24 mb-2 lg:mb-4"
                  />
                  <h4 className="text-3xl lg:text-4xl font-bold mb-1 lg:mb-2">
                    40
                  </h4>
                  <p className="text-base lg:text-lg text-gray-700">
                    Service Centers
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="bg-gray-100 p-2 px-6 rounded-lg shadow-lg animate__animated animate__fadeIn animate__delay-1s">
        <ProductRange />
      </div>
      <div className="bg-gray-100 p-2 px-6 rounded-lg shadow-lg animate__animated animate__fadeIn animate__delay-1s">
        <WhyChooseUs />
      </div>
      <div className="bg-gray-100 p-2 px-6 rounded-lg shadow-lg animate__animated animate__fadeIn animate__delay-1s">
        <PlanBattries />
      </div>

      <div className="bg-gray-100 p-6 rounded-lg shadow-lg animate__animated animate__fadeIn animate__delay-1s">
        <div className="text-xl font-semibold text-orange-700 p-4">
          <p className="flex justify-center">
            LET&apos;S MEET THE PEOPLE BEHIND AVS Battery
          </p>
        </div>
        <div className="font-bold text-4xl font-serif">
          <p className="flex justify-center">Our Team</p>
        </div>
        <div className="gap-5 p-10 flex justify-center items-center">
          <div className="grid md:grid-cols-2 gap-10">
            <div className="p-10 bg-white shadow-md rounded-lg shadow-orange-800 flex flex-col items-center hover:scale-[1.02] duration-500">
              <img
                src={Director1}
                alt="Director1"
                className="w-[280px] md:w-[350px] lg:w-[400px] max-h-96 rounded-lg shadow-xl mb-4 transition-transform duration-300 hover:scale-105"
              />
              <p className="font-semibold text-xl p-2">Amit Kumar Singh</p>
              <p className="font-semibold text-orange-700">
                Director- AVS BATTERY
              </p>
            </div>
            <div className="p-10 bg-white shadow-md rounded-lg shadow-orange-800 flex flex-col items-center hover:scale-[1.02] duration-500">
              <img
                src={Director2}
                className="w-[280px] md:w-[350px] lg:w-[400px] max-h-96 rounded-lg shadow-xl mb-4 transition-transform duration-300 hover:scale-105"
                alt="Director2"
              />
              <p className="font-semibold text-xl p-2">Basanti Devi</p>
              <p className="font-semibold text-orange-700">
                Director- AVS BATTERY
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-white text-center py-6 relative">
        <div className="absolute inset-0 bg-[#171717] opacity-50"></div>
        <div className="relative z-10">
          <div className="container mx-auto">
            <h2 className="text-2xl md:text-3xl font-bold">
              Book a battery by getting in touch with us
            </h2>
            <span className="flex items-center justify-center text-xl mt-4">
              <li className="flex gap-8 text-base md:text-lg font-medium">
                <a
                  href="tel:7667131756"
                  className="flex items-center text-white hover:text-orange-600"
                >
                  <i className="fa-solid fa-phone mr-2 md:mr-3"></i> 7667131756
                </a>
                <a
                  href="tel:8294205717"
                  className="flex items-center text-white hover:text-orange-600"
                >
                  <i className="fa-solid fa-phone mr-2 md:mr-3"></i> 8294205717
                </a>
              </li>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
