import { useState } from "react";

function BatteryBox({ data, batteryID }) {
  const [batteryLoad, setBatteryLoad] = useState(true);

  return (
    <>
      {data[batteryID].map((battery, id) => (
        <div
          key={id}
          className="flex flex-col lg:flex-row gap-8 lg:gap-16 items-center "
        >
          {/* Battery */}
          <div className="relative w-full lg:w-1/2 transition-transform transform hover:scale-105 ease-in-out duration-300">
            {batteryLoad && (
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="w-8 h-8 border-4 border-gray-300 border-t-4 border-t-orange-500 rounded-full animate-spin"></span>
              </span>
            )}
            <img
              className={`w-full ${
                batteryLoad ? "hidden" : "block"
              } transition-opacity duration-500`}
              src={battery.img}
              alt="battery_img"
              onLoad={() => setBatteryLoad(false)}
            />
          </div>
          {/* Description */}
          <div className="w-full lg:w-1/2 flex flex-col gap-2 px-4 lg:px-8">
            <div className="bg-orange-500 text-white text-lg lg:text-xl font-bold flex items-center justify-between px-4 py-3  rounded-lg shadow-md transition-shadow duration-300 hover:shadow-lg">
              <span className="text-xl lg:text-2xl">{battery.model}</span>
            </div>
            <div className="grid grid-cols-1 gap-4 text-base lg:text-md">
              {[
                // { label: "Type", value: battery.type },
                { label: "Capacity", value: battery.Capacity },
                { label: "Price", value: battery.Price },
                // { label: "Weight", value: battery.weight },
                // { label: "Dimensions", value: battery.dimensions },
                { label: "Warranty", value: battery.Warranty },
                { label: "Application", value: battery.Application },
              ].map(({ label, value }, idx) => (
                <div
                  key={idx}
                  className="grid grid-cols-2 text-center py-2 px-4 border-b border-gray-300 transition-colors duration-300 hover:bg-gray-100"
                >
                  <span className="border-r border-gray-300">{label}</span>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            {/* <a
              className="py-2 px-6 text-lg lg:text-xl font-semibold text-white bg-orange-500 rounded-md text-center hover:bg-orange-600 shadow-md transform transition-transform duration-200 hover:scale-105"
              href="#booking-section"
            >
              Buy Now
            </a> */}
          </div>
        </div>
      ))}
    </>
  );
}

export default BatteryBox;
