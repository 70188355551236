import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AdminPanel = () => {
  const [registrations, setRegistrations] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const response = await axios.get(
          "https://avs-battery.onrender.com/api/register",
          {
            // const response = await axios.get("http://localhost:5000/api/register", {
            params: { search, sortBy, order },
          }
        );
        setRegistrations(response.data);
      } catch (error) {
        console.error("Error fetching registrations:", error);
        toast.error("Failed to fetch registrations.");
      }
    };

    fetchRegistrations();
  }, [search, sortBy, order]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSortChange = (e) => {
    const [field, order] = e.target.value.split(":");
    setSortBy(field);
    setOrder(order);
  };

  return (
    <div className=" w-full mx-auto px-4 md:px-8 mt-16 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold text-gray-900 mb-6 pt-4 flex justify-center">
        Admin Panel
      </h1>
      <h4 className="text-4xl font-bold text-gray-900 mb-6 pt-4 ">
        Registered Batteries Information:
      </h4>

      <div className="flex flex-col lg:flex-row items-center lg:justify-between mb-6">
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
          className="border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all w-full lg:w-1/3 mb-4 lg:mb-0"
        />

        <div className="flex items-center space-x-4">
          <label className="text-lg font-semibold">Sort By:</label>
          <select
            onChange={handleSortChange}
            className="border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 transition-all"
          >
            <option value="createdAt:asc">Date (Ascending)</option>
            <option value="createdAt:desc">Date (Descending)</option>
            <option value="name:asc">Name (A-Z)</option>
            <option value="name:desc">Name (Z-A)</option>
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Phone
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Battery Serial
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Purchase Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Warranty Period
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Registered
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {registrations.map((registration) => (
              <tr
                key={registration._id}
                className="hover:bg-gray-50 transition-all"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {registration.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {registration.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {registration.phone}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {registration.batterySerial}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(registration.purchaseDate).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {registration.warrantyPeriod}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(registration.createdAt).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPanel;
