import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { menu, close } from "ionicons/icons";
import axios from "axios";
import "tailwindcss/tailwind.css";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setIsAdmin(false);
        return;
      }

      try {
        // Verify the token with the backend
        const response = await axios.get(
          "https://avs-battery.onrender.com/api/admin/verifyToken",
          // "http://localhost:5000/api/admin/verifyToken",
          {
            headers: { "x-auth-token": token },
          }
        );

        if (response.data.success) {
          setIsAdmin(true); // Token is valid
        } else {
          setIsAdmin(false); // Token is invalid
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsAdmin(false); // Error during verification
      }
    };

    verifyToken();

    setIsMenuOpen(false);
    setIsDropdownOpen(false);
    window.scrollTo(0, 0); // Scroll to top whenever the location changes
  }, [location]);

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAdmin(false);
    navigate("/");
  };

  const handleMouseEnter = () => {
    if (!isMenuOpen) {
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMenuOpen) {
      setIsDropdownOpen(false);
    }
  };

  return (
    <header className="bg-[#171717] z-50 fixed top-0 left-0 w-full border-b-4 border-orange-300">
      {/* {isAdmin ? null : (
        <div className="flex items-center bg-orange-500 py-1 justify-between">
          <Link to="/">
            <p className="flex items-center hover:no-underline text-white gap-2 text-lg px-2">
              <span className="font-semibold">Contact : 1234535325</span>
            </p>
          </Link>
          <Link to="/">
            <p className="flex items-center hover:no-underline text-white gap-2 text-lg px-2">
              <span className="font-semibold">Contact2</span>
            </p>
          </Link>
        </div>
      )} */}
      <div className="flex justify-between items-center w-[90%] mx-auto relative font-normal md:font-semibold">
        <nav className="flex justify-between items-center w-[100%] font-normal md:font-semibold">
          <div>
            <Link
              className="hover:text-orange-500"
              to={isAdmin ? "/adminPannel" : "/"}
              onClick={() => setIsMenuOpen(false)}
            >
              <img
                className="block sm:hidden w-46 h-16 cursor-pointer"
                src="logo.png"
                alt="Logo"
              />
              <img
                className="hidden sm:block w-46 h-16 cursor-pointer"
                src="logo.png"
                alt="Logo"
              />
            </Link>
          </div>

          <div
            className={`nav-links duration-500 md:static fixed bg-[#171717] text-white md:min-h-fit right-[0] ${
              isMenuOpen
                ? "absolute top-20 rounded-xl p-4 z-30 border-b-4 border-orange-300"
                : "top-[-100%]"
            } md:w-auto w-20% flex items-center z-40`}
          >
            <ul className="flex md:flex-row flex-col md:items-center md:gap-[3vw] gap-4 text-lg">
              {isAdmin ? (
                <>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/adminPannel"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Registered&nbsp;Batteries
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/admin-claimwarranty"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Claim&nbsp;warranty
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/admin-contacts"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Admin&nbsp;Contacts
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/admin-updateCredentials"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Update&nbsp;Credentials
                    </Link>
                  </li>
                  <div className="flex items-center gap-4">
                    <button
                      className="bg-orange-500 text-white py-2 px-6 rounded-lg shadow-lg border-2 border-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/about"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      About&nbsp;Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/Batteries"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Batteries
                    </Link>
                  </li>
                  <li
                    className="relative cursor-pointer"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    <div className="hover:text-orange-500 flex">
                      Warranty
                      <svg
                        className="-mr-1 h-6 w-5"
                        viewBox="0 -2 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    {isDropdownOpen && (
                      <ul
                        className={`absolute ${
                          isMenuOpen ? "right-32 top-0 w-48" : "left-0 w-48"
                        } bg-[#171717]  rounded-md shadow-lg`}
                      >
                        <li>
                          <Link
                            className="block px-1 py-2 bg-[#171717] text-white hover:text-orange-300"
                            to="/RegistrationPage"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Register&nbsp;for&nbsp;Battery
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="block px-1 py-2 bg-[#171717] text-white hover:text-orange-300"
                            to="/ViewRegistration"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            View&nbsp;Registration
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="block px-1 py-2 bg-[#171717] text-white hover:text-orange-300"
                            to="/claimWarranty"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Claim&nbsp;Warranty
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="block px-1 py-2 bg-[#171717] text-white hover:text-orange-300"
                            to="/WarrantyTerms"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Terms&nbsp;and&nbsp;Conditions
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/Gallery"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="hover:text-orange-500"
                      to="/testimonials"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Testimonials
                    </Link>
                  </li>
                  <div className="flex items-center gap-4 text-lg">
                    <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
                      <button className="bg-orange-500 text-white py-2 px-6 rounded-lg shadow-lg border-2 border-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out">
                        Contact&nbsp;Us
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </ul>
          </div>
        </nav>
        <div className="flex items-center gap-4">
          <IonIcon
            icon={isMenuOpen ? close : menu}
            onClick={onToggleMenu}
            className="text-3xl cursor-pointer md:hidden text-white"
          />
        </div>
      </div>
    </header>
  );
};

export default NavBar;
