import SelectBattery from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import InstallBattery from "../images/plan/icon3.png";

function PlanBattries() {
  return (
    <section className="bg-gradient-to-r from-gray-50 to-gray-100 pt-10 pb-4">
      <div className="container mx-auto px-4">
        <div className="w-[90%] mx-auto">
          <div className="text-center mb-8">
            <h3 className="text-2xl md:text-3xl lg:text-4xl font-medium text-gray-800">
              Quick & Easy AVS Battery
            </h3>
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 my-3">
              Power-Packed Batteries for a Smoother Ride!
            </h2>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <img
                src={SelectBattery}
                alt="Select Battery"
                className="w-24 lg:w-32 mx-auto mb-2 transition-transform transform hover:scale-110 duration-300 ease-in-out"
              />
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Select Batteries
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                We offer a wide range of batteries to meet all your vehicle's
                power needs. Choose the perfect battery for optimal performance.
              </p>
            </div>

            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <img
                src={Contact}
                alt="Contact Operator"
                className="w-24 lg:w-32 mx-auto mb-2 transition-transform transform hover:scale-110 duration-300 ease-in-out"
              />
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Contact Operator
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Our knowledgeable and friendly operators are always ready to
                help with any questions or concerns you may have.
              </p>
            </div>

            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <img
                src={InstallBattery}
                alt="Install Battery"
                className="w-24 lg:w-32 mx-auto mb-2 transition-transform transform hover:scale-110 duration-300 ease-in-out"
              />
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Install & Drive
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Get your battery installed quickly and efficiently, and get back
                on the road with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlanBattries;
