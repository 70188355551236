import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://avs-battery.onrender.com/api/contact",
        // "http://localhost:5000/api/contact",
        {
          headers: { "x-auth-token": token },
        }
      );
      const sortedContacts = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setContacts(sortedContacts);
    } catch (err) {
      setError("Error fetching contacts");
    }
  };

  return (
    <div className=" w-full mx-auto px-4 md:px-8 mt-16 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold text-gray-900 mb-6 pt-4 flex justify-center">
        Contact Submissions
      </h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="space-y-4">
        {contacts.map((contact) => (
          <div key={contact._id} className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold">{contact.name}</h2>
            <p className="font-semibold text-lg">Subject: {contact.subject}</p>
            <p>{contact.message}</p>
            <p className="text-sm text-gray-600">Email: {contact.email}</p>
            <p className="text-sm text-gray-600">
              Submitted on: {new Date(contact.createdAt).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminContacts;
