import React, { useState } from "react";
import axios from "axios";
import HeroPages from "../components/HeroPages";
import { toast } from "react-toastify";

const ClaimWarrantyPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    batterySerial: "",
    issueDescription: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(
        "https://avs-battery.onrender.com//api/warranty-claim",
        formData
      );
      // await axios.post("http://localhost:5000/api/warranty-claim", formData);
      toast.success("Warranty claim submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        batterySerial: "",
        issueDescription: "",
      });
    } catch (error) {
      //  Check if the error response exists and has a message
      const errorMessage =
        error.response?.data?.message || "Failed to submit warranty claim.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="lg:py-6">
        <HeroPages name="Claim Warranty" />
        <div className="container mx-auto px-4 lg:px-8 w-full lg:w-[95%]">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 py-12 bg-cover bg-center bg-no-repeat rounded-lg"
            style={{
              backgroundImage:
                "url('/src/images/banners/bg-claim-warranty.png')",
            }}
          >
            <div className="flex flex-col text-[#171717] max-w-4xl mx-auto p-6 bg-white bg-opacity-80 rounded-lg shadow-md">
              <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-8">
                Claim Your AVS Battery Warranty
              </h2>
              <p className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8">
                If you are experiencing issues with your AVS Battery , you can
                claim your warranty by filling out the form. Please provide
                accurate details to ensure a smooth and efficient process.
              </p>
              <ul className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8 list-disc list-inside">
                <li>High performance and long lifespan</li>
                <li>Comprehensive warranty coverage</li>
                <li>24/7 customer support</li>
                <li>Eco-friendly and sustainable</li>
              </ul>
            </div>
            <div className="flex flex-col p-6 bg-white bg-opacity-80 rounded-lg shadow-lg">
              <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Full Name <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder='E.g: "Joe Shmoe"'
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full focus:ring-2 focus:ring-orange-400 transition"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Email <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="youremail@example.com"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full focus:ring-2 focus:ring-orange-400 transition"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Phone Number <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    required
                    placeholder="(123) 456-7890"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full focus:ring-2 focus:ring-orange-400 transition"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Battery Serial Number{" "}
                    <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="batterySerial"
                    required
                    placeholder="Battery Serial Number"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full focus:ring-2 focus:ring-orange-400 transition"
                    value={formData.batterySerial}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Describe the Issue{" "}
                    <span className="text-orange-500">*</span>
                  </label>
                  <textarea
                    name="issueDescription"
                    required
                    placeholder="Describe the issue with your battery..."
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full h-32 focus:ring-2 focus:ring-orange-400 transition"
                    value={formData.issueDescription}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className={`bg-orange-500 py-3 px-6 text-md lg:text-lg text-white font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition w-full ${
                    loading ? "cursor-wait opacity-70" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit Warranty Claim"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClaimWarrantyPage;
