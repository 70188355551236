function Banner() {
  return (
    <section className="w-full bg-[#171717] py-8 text-center flex items-center">
      <div className="container mx-auto px-4 md:px-6 lg:px-8 xl:px-12">
        <div className="text-white flex flex-col items-center">
          <div className="flex flex-col gap-6 ">
            <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-snug md:leading-tight">
              Save big with our affordable AVS Battery!
            </h2>
            <p className="text-md sm:text-lg md:text-xl lg:text-2xl">
              Most trusted brand for Inverter Battery & Home UPS! Browse through
              for Inverter, E-Rickshaw, Tractor, Solar, Pickup, Tempu, House,
              Shop Batteries and all type of batteries are available on
              afforadabe rate.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
