import React, { useState } from "react";
import HeroPages from "../components/HeroPages";
import axios from "axios";
import { toast } from "react-toastify";

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    batterySerial: "",
    purchaseDate: "",
    warrantyPeriod: "1 year",
  });

  const [loading, setLoading] = useState(false); // For managing the loading state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await axios.post(
        "https://avs-battery.onrender.com/api/register",
        formData,
        {
          // await axios.post("http://localhost:5000/api/register", formData, {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Battery registered successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        batterySerial: "",
        purchaseDate: "",
        warrantyPeriod: "1 year",
      });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to register battery. Please try again.");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <section className="lg:py-6">
        <HeroPages name="Register Your Battery" />
        <div className="container mx-auto w-[95%]">
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 py-12 bg-cover bg-center bg-no-repeat rounded-lg"
            style={{
              backgroundImage: "url('/src/images/banners/bg-register.png')",
            }}
          >
            <div className="flex flex-col text-[#171717] max-w-4xl mx-auto p-6 bg-white bg-opacity-80 rounded-lg">
              <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-8">
                AVS Battery Registration
              </h2>
              <p className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8">
                Ensure your battery's warranty is protected by registering it
                today. AVS batteries are known for their reliability and
                performance. Designed to last, they provide optimal power and
                are perfect for various applications. Register your battery to
                benefit from our comprehensive warranty program.
              </p>
              <ul className="text-md lg:text-lg text-gray-700 leading-relaxed mb-6 lg:mb-8 list-disc list-inside">
                <li>High performance and long lifespan</li>
                <li>Comprehensive warranty coverage</li>
                <li>24/7 customer support</li>
                <li>Eco-friendly and sustainable</li>
              </ul>
            </div>
            <div className="flex flex-col p-6 bg-white bg-opacity-80 rounded-lg shadow-md">
              <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Full Name <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder='E.g: "Joe Shmoe"'
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Email <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="youremail@example.com"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Phone Number <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    required
                    placeholder="(123) 456-7890"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Battery Serial Number{" "}
                    <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="batterySerial"
                    required
                    placeholder="Battery Serial Number"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.batterySerial}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Purchase Date <span className="text-orange-500">*</span>
                  </label>
                  <input
                    type="date"
                    name="purchaseDate"
                    required
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.purchaseDate}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-md lg:text-lg font-semibold mb-2">
                    Warranty Period <span className="text-orange-500">*</span>
                  </label>
                  <select
                    name="warrantyPeriod"
                    className="bg-gray-200 py-3 px-4 text-md lg:text-lg rounded-lg outline-none w-full"
                    value={formData.warrantyPeriod}
                    onChange={handleChange}
                  >
                    <option>6 months</option>
                    <option>1 year</option>
                    <option>2 years</option>
                    <option>3 years</option>
                    <option>4 years</option>
                    <option>5 years</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className="bg-orange-500 py-3 px-6 text-md lg:text-lg text-white font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition w-full"
                >
                  {loading ? (
                    <i className="fa-solid fa-spinner fa-spin mr-2"></i>
                  ) : (
                    <i className="fa-solid fa-registered mr-2"></i>
                  )}
                  {loading ? "Registering..." : "Register Battery"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegistrationPage;
