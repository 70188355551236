import React from "react";

function WhyChooseUs() {
  return (
    <section className="bg-gradient-to-r from-gray-50 to-gray-100 pt-4 pb-4">
      <div className="container mx-auto px-4">
        <div className="w-[90%] mx-auto">
          <div className="text-center mb-8">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-900 my-3">
              Why Choose Us
            </h2>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-12">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Quality Assurance
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                We adhere to the highest standards of quality, ensuring that
                every battery we produce is reliable and long-lasting.
              </p>
            </div>

            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Innovation
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Our team of experts is constantly researching and developing new
                technologies to improve our products and meet the evolving needs
                of our customers.
              </p>
            </div>

            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Sustainability
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                We are committed to producing eco-friendly batteries that
                contribute to a greener and more sustainable future.
              </p>
            </div>

            <div className="text-center p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
              <h3 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                Customer Focus
              </h3>
              <p className="text-base lg:text-lg text-gray-600">
                Our customers are at the heart of everything we do. We strive to
                provide exceptional service and support to ensure your complete
                satisfaction.
              </p>
            </div>
          </div>

          <div className="mt-8 p-6 text-center">
            <p className="text-base lg:text-lg text-gray-700 leading-relaxed">
              Join us in our mission to power the world sustainably and
              efficiently. Follow us on our social media channels to stay
              updated with our latest products, innovations, and industry
              insights.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
