import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";

function TestimonialsPage() {
  return (
    <>
      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
        <Testimonials />
        <div className="bg-gray-900 text-white text-center py-6 relative">
          <div className="absolute inset-0 bg-[#171717] opacity-50"></div>
          <div className="relative z-10">
            <div className="container mx-auto">
              <h2 className="text-2xl md:text-3xl font-bold">
                Book a battery by getting in touch with us
              </h2>
              <span className="flex items-center justify-center text-xl mt-4">
                <li className="flex gap-8 text-base md:text-lg font-medium">
                  <a
                    href="tel:7667131756"
                    className="flex items-center text-white hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                    7667131756
                  </a>
                  <a
                    href="tel:8294205717"
                    className="flex items-center text-white hover:text-orange-600"
                  >
                    <i className="fa-solid fa-phone mr-2 md:mr-3"></i>{" "}
                    8294205717
                  </a>
                </li>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialsPage;
