import React from "react";
import HeroPages from "../components/HeroPages";

const RefundCancellationPolicy = () => {
  return (
    <>
      <section className="lg:py-6 bg-gray-50">
        <HeroPages name="Refund and Cancellation Policy" />
        <div className="container mx-auto px-4 lg:px-8 w-[95%]">
          <div className="bg-white p-6 lg:p-8 rounded-lg space-y-12">
            {/* Refund and Cancellation Policy */}
            <div>
              <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                Refund Policy
              </h2>
              <div className="space-y-4">
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  At AVS Battery, we strive to provide the best quality products
                  to our customers. However, if you are not satisfied with your
                  purchase, please review our refund policy below.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Eligibility for Refunds
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Refunds are available for products that are returned within
                  [Refund Period] of the purchase date, provided they are in
                  their original, unused condition and in the original
                  packaging. Proof of purchase is required for all refund
                  requests.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Non-Refundable Items
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Certain items are non-refundable, including custom orders,
                  items on sale, and products that have been used or damaged by
                  the customer. Please ensure you check the eligibility criteria
                  before requesting a refund.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Refund Process
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  To initiate a refund, please contact our customer service team
                  with your purchase details. Once your return is received and
                  inspected, we will notify you of the approval or rejection of
                  your refund. If approved, your refund will be processed, and a
                  credit will automatically be applied to your original method
                  of payment within a certain amount of days.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Partial Refunds
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  In some cases, only partial refunds are granted (e.g., if the
                  item is returned after [Partial Refund Period] or is not in
                  its original condition). Partial refunds will be evaluated on
                  a case-by-case basis.
                </p>
              </div>
            </div>
            {/* Cancellation Policy */}
            <div>
              <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                Cancellation Policy
              </h2>
              <div className="space-y-4">
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Order Cancellations
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Orders can be canceled within [Cancellation Period] after the
                  order is placed, provided the order has not yet been shipped.
                  If the order has already been shipped, it cannot be canceled,
                  and the standard refund policy will apply.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  How to Cancel
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  To cancel an order, please contact our customer service team
                  as soon as possible with your order details. If the
                  cancellation request is within the allowed time frame, we will
                  cancel the order and issue a refund to your original payment
                  method.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Refunds for Canceled Orders
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  If your cancellation request is approved, you will receive a
                  full refund, including any shipping fees. The refund will be
                  processed within a few business days and applied to your
                  original payment method.
                </p>
                <h3 className="text-xl lg:text-2xl font-semibold">
                  Important Notes
                </h3>
                <p className="text-sm lg:text-md text-gray-700 leading-relaxed">
                  Please note that custom orders or special requests may not be
                  eligible for cancellation once processing has begun. If you
                  have any questions regarding cancellations or refunds, please
                  contact our support team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundCancellationPolicy;
